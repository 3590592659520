<script lang="ts" context="module">
    import { runMutation, runQueryWithPromise } from 'helpers/graphql-query'
    import { SignupDocument, ConfirmAccountDocument } from '_config/graphql/tags-generated'

    import { GetAgenciesDocument, CheckEmailExistDocument } from '_config/graphql/tags-generated'

    import { goto } from '$app/navigation'

    export async function emailCheck(email: string, client: GraphqlClient) {
        const result = await runQueryWithPromise(
            CheckEmailExistDocument,
            {
                email: email,
            } as SomeObject,
            client
        )

        if (result?.error) goto(`/error#signup/emailCheck`)

        return result?.data?.emailExists
    }

    export async function getAgencies(client: GraphqlClient) {
        const result = await runQueryWithPromise(GetAgenciesDocument, {} as SomeObject, client)
        if (result?.error) goto(`/error#signup/getAgencies`)

        return result?.data?.agencies?.agencies as AgencyResponseDetail[]
    }

    export async function signupUser(request: SignupMutationVariables, client: GraphqlClient) {
        if (!request) return

        const result = await runMutation(SignupDocument, request, client)
        if (result?.error) goto(`/error#signup/create`)

        return result?.data
    }

    export async function confirmAccount(request: ConfirmAccountMutationVariables, client: GraphqlClient) {
        if (!request) return

        const result = await runMutation(ConfirmAccountDocument, request, client)
        if (result?.error) goto(`/error#signup/confirm`)

        return result?.data
    }
</script>
