<script lang="ts">
    import { createEventDispatcher } from 'svelte'

    export let id: string = ''
    export let value: Maybe<string> | undefined | number
    export let required: boolean = false
    export let isError: boolean | undefined = false
    export let showErrorMessage: boolean = true
    export let pattern: string = ''
    export let hint: string = ''
    export let maxlength: number = -1
    export let disabled: boolean = false
    export let isPassword: boolean = false
    export let style = ''
    export let type = 'text'

    import { browser } from '$app/env'

    if (browser) validation()

    const dispatch = createEventDispatcher()

    function onInput(event: any) {
        dispatch('input', event)

        validation()
    }

    function onBlur(event: any) {
        dispatch('blur', event)

        validation()
    }

    function validation() {
        if (pattern && value) {
            isError = !RegExp(pattern).test(value.toString())
        } else isError = required && value == ''
    }

    $: if (value) validation()
</script>

{#if isPassword}
    <input
        type="password"
        autocomplete="OFF"
        class={(required && value == '') || isError ? 'error' : style}
        bind:value
        on:input={onInput}
        on:blur={onBlur}
        {pattern}
        {maxlength}
        {disabled}
    />
{:else if type === 'number'}
    <input {id} type="number" class={(required && value == '') || isError ? 'error' : style} bind:value on:input={onInput} on:blur={onBlur} {pattern} {maxlength} {disabled} />
{:else}
    <input {id} type="text" class={(required && value == '') || isError ? 'error' : style} bind:value on:input={onInput} on:blur={onBlur} {pattern} {maxlength} {disabled} />
{/if}

{#if showErrorMessage && isError}
    {#if required && !value}
        <span class="error">This field is required.</span>
    {:else if hint}
        <span class="error">{@html hint}</span>
    {/if}
{/if}

<style lang="scss">
    input {
        &.error {
            border: 1px solid var(--color-error);
        }
    }

    span {
        &.error {
            margin: 0;
            font-size: small;
            color: var(--color-error);
        }
    }
</style>
